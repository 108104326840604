<template>
  <a-spin :spinning="spinning">
    <div>
      <a-card class="card" title="基础信息" :bordered="false">
        <div slot="extra">
          <a-button>
            <router-link :to="{ name: 'WmsCmsBiz' }">取消</router-link>
          </a-button>
          <a-divider type="vertical" />
          <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
        </div>
        <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
          <a-row :gutter="20">
            <a-col :span="6">
              <a-form-model-item label="业务编号">
                <a-input v-model="form.serial_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="报关单号">
                <a-input v-model="form.report_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="计划号">
                <a-input v-model="form.plan_id" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="委托客户" :autoLink="false">
                <a-input v-model="form.consignor_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="经营单位">
                <a-input v-model="form.operator_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="报关单位" :autoLink="false">
                <a-input v-model="form.customs_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="联系人">
                <a-input v-model="form.contact_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="联系电话">
                <a-input v-model="form.contact_phone" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="运输单位">
                <a-input v-model="form.trans_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="收货单位">
                <a-input v-model="form.receiver" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="金额">
                <a-input v-model="form.charge" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="品名">
                <a-input v-model="form.total_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="数量">
                <a-input v-model="form.total_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="毛重">
                <a-input v-model="form.total_gross_weight" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="体积">
                <a-input v-model="form.total_volume" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="净重">
                <a-input v-model="form.total_net_weight" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="进库日期">
                <a-date-picker v-model="form.purchase_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="出库日期">
                <a-date-picker v-model="form.release_date" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="监管方式">
                <a-input v-model="form.supervise_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="用车数量">
                <a-input v-model="form.vehicle_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="集装箱号">
                <a-input v-model="form.box_serial_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="是否押运">
                <a-switch checked-children="是" un-checked-children="否" v-model="form.escort" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="企业类型">
                <a-input v-model="form.company_type_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="违规性质">
                <a-input v-model="form.violation" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="布控部门">
                <a-input v-model="form.control_department" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="布控指令">
                <a-input v-model="form.control_command" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="集装箱号">
                <a-input v-model="form.box_serial_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="箱型">
                <a-input v-model="form.box_type_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="箱量">
                <a-input v-model="form.box_num" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="货物状态">
                <a-input v-model="form.commodity_type_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="查验方式">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择查验方式"
                  mode="multiple"
                  style="width: 100%"
                  v-model="form.inspect_type"
                  disabled
                >
                  <a-select-option v-for="op in inspectType" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="查验情况简述">
                <a-input v-model="form.sketch" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="货物处置">
                <a-input v-model="form.commodity_deal" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="备注">
                <a-textarea v-model="form.remark" disabled></a-textarea>
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left">违规申报内容</a-divider>
            <a-col :span="6">
              <a-form-model-item label="品名">
                <a-input v-model="form.violation_name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="HS">
                <a-input v-model="form.hs" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="金额">
                <a-input v-model="form.violation_charge" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="原产地">
                <a-input v-model="form.origin_area" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="其它">
                <a-input v-model="form.other_msg" disabled />
              </a-form-model-item>
            </a-col>
            <a-divider orientation="left"></a-divider>
            <a-col :span="6">
              <a-form-model-item label="仓储进库日期">
                <a-date-picker v-model="form.in_date" @change="changeInDate" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="仓储出库日期">
                <a-date-picker v-model="form.out_date" @change="changeOutDate" class="full-width"></a-date-picker>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="入库总立方">
                <a-input-number v-model="form.in_cube" style="width:100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="入库操作员">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择操作员"
                  style="width: 100%"
                  ref="operator_name"
                  v-model="form.in_operator"
                >
                  <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="出库总立方">
                <a-input-number v-model="form.out_cube" style="width:100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="出库操作员">
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择操作员"
                  style="width: 100%"
                  ref="operator_name"
                  v-model="form.out_operator"
                >
                  <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="总天数">
                <a-input-number v-model="form.wms_days" class="full-width" />
              </a-form-model-item>
            </a-col>
            <a-col :span="18">
              <a-form-model-item label="备注">
                <a-textarea v-model="form.wms_remark"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </a-card>
      <a-card class="card" title="其它信息" :bordered="false">
        <a-tabs
          :activeKey="activeKey"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane tab="操作信息" key="1">
            <edit-table
              scroll
              :columns="operateColumns"
              :sourceData="operateData"
              @getTableDate="getOperateData"
            ></edit-table>
          </a-tab-pane>
          <a-tab-pane tab="仓储查验费用" key="2" forceRender>
            <edit-table
              scroll
              :columns="chargeFeeColumns"
              :sourceData="chargeFeeData"
              @getTableDate="getChargeFeeData"
            ></edit-table>
          </a-tab-pane>
          <a-tab-pane tab="海关查验费用" key="3">
            <edit-table
              :disabled="true"
              :columns="feeColumns"
              :sourceData="checkInFeeData"
              @getTableDate="getCheckInFeeData"
            ></edit-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </div>
  </a-spin>
</template>

<script>
import { getCommonOptions } from '@/api/common'
import { getCmsFeeTypes } from '@/api/wms/sale'
import { getCmsMetaOption } from '@/api/cms'
import { getWmsCmsInfoDetail, getWmsDataOption, updateWmsCmsInfo } from '@/api/wms'
import { EditTable } from '@/components'
import { filterOption } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick'

export default {
  components: {
    EditTable,
    preventReClick
  },
  data() {
    return {
      checkInFeeData: [],
      spinning: false,
      id: null,
      form: {},
      sourceForm: {
        serial_num: '',
        report_num: '',
        consignor: null,
        consignor_name: '',
        operator_name: '',
        customs_company: null,
        customs_name: '',
        contact_name: '',
        contact_phone: '',
        trans_name: '',
        receiver: '',
        charge: '',
        total_name: '',
        total_num: '',
        total_gross_weight: '',
        total_volume: '',
        total_net_weight: '',
        purchase_date: null,
        release_date: null,
        supervise_type: null,
        supervise_name: '',
        vehicle_num: '',
        box_serial_num: '',
        escort: false,
        remark: '',
        company_type: null,
        company_type_name: '',
        violation: '',
        control_department: '',
        control_command: '',
        sketch: '',
        commodity_deal: '',
        commodity_type: null,
        commodity_type_name: '',
        inspect_type: null,
        violation_name: '',
        hs: '',
        violation_charge: '',
        origin_area: '',
        other_msg: '',
        // 仓储查验
        in_date: null,
        out_date: null,
        in_cube: null,
        in_operator: null,
        out_cube: null,
        out_operator: null,
        wms_days: null,
        wms_remark: ''
      },
      isEdit: false,
      disabled: false,
      activeKey: '1',
      customerOps: [],
      cusCompanyOps: [],
      customCompanyMap: {}, // 报关单位对应联系信息map
      inspectType: [],
      superviseOps: [],
      componyOps: [],
      commodityOps: [],
      operateData: [],
      operatorOps: [],
      feeData: [],
      rules: {
        auth_agency: [{ required: true, message: '请选择委托客户', trigger: 'blur' }]
      },
      operateColumns: [
        {
          title: '操作人',
          dataIndex: 'operator_id',
          width: 100,
          extra: {
            type: 'select',
            func: getWmsDataOption,
            params: ['operator'],
            options: []
          }
        },
        {
          title: '操作内容',
          dataIndex: 'content',
          width: 200,
          extra: {
            type: 'autocomplete',
            func: getWmsDataOption,
            params: ['cms_operation_type'],
            options: []
          }
        },
        {
          title: '箱型',
          dataIndex: 'box_id',
          width: 100,
          extra: {
            type: 'select',
            func: getCommonOptions,
            params: ['box_info'],
            options: []
          }
        },
        {
          title: '箱量/立方',
          dataIndex: 'box_num',
          width: 100
        }
      ],
      chargeFeeData: [],
      payFeeData: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: getCmsFeeTypes,
            params: [],
            options: [],
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 2 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        {
          title: '结算方式',
          dataIndex: 'charge_type',
          extra: {
            defaultValue: 1,
            type: 'select',
            options: [
              {
                name: '现金', value: 1
              },
              {
                name: '月结', value: 2
              }
            ]
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            extraFunc: this.calcTotalCharge,
            disabled: record => {
              return !(
                record.fee_status === 1 ||
                record.fee_status === 2 ||
                record.fee_status === 4 ||
                !record.fee_status
              )
            }
          }
        },
        // {
        //   title: '交易号',
        //   dataIndex: 'trx_num',
        //   extra: {
        //     type: 'text'
        //   }
        // },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            return text && this.GLOBAL.feeStatusMaps[text].name
          },
          extra: {
            type: 'text'
          }
        }
      ],
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 4, type: 1 }],
            options: [],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '结算方式',
          dataIndex: 'charge_type',
          extra: {
            defaultValue: 1,
            type: 'select',
            options: [
              {
                name: '现金', value: 1
              },
              {
                name: '月结', value: 2
              }
            ],
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            disabled: (record) => {
              return true
            }
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            return this.GLOBAL.feeStatusMaps[text].name
          },
          extra: {
            type: 'text'
          }
        }
      ],
      totalChargeFee: 0
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'WmsCmsBizEdit') {
        // 编辑页路由监控
        this.handleFormReset()
        this.disabled = newRoute.query.disabled
        this.getEditData(newRoute.params.id)
      }
    }
  },
  mounted() {
    this.disabled = this.$route.query.disabled === true
    this.getEditData(this.$route.params.id)
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
  },
  methods: {
    filterOption,
    getCommonOptions,
    moment,
    // 海关查验
    getCheckInFeeData(data) {
      this.checkInFeeData = data
    },
    editTableDisFlag(data) {
      const feeStatus = data.fee_status.toString()
      if (feeStatus >= '3' && feeStatus !== '4') {
        return true
      } else {
        return false
      }
    },
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.activeKey = '1'
      this.operateData = []
      this.chargeFeeData = []
      this.checkInFeeData = []
      this.totalChargeFee = 0
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    getEditData(id) {
      this.isEdit = true
      this.spinning = true
      this.id = id
      getWmsCmsInfoDetail({ id: id })
        .then(v => {
          const record = v.check_in_record
          record['purchase_date'] = record['purchase_date'] ? moment(record['purchase_date'], 'YYYY/MM/DD') : null
          record['release_date'] = record['release_date'] ? moment(record['release_date'], 'YYYY/MM/DD') : null
          record['in_date'] = record['in_date'] ? moment(record['in_date'], 'YYYY/MM/DD') : null
          record['out_date'] = record['out_date'] ? moment(record['out_date'], 'YYYY/MM/DD') : null
          this.form = {
            ...record
          }
          v.charge_fee_list.forEach(item => {
            if (item.fee_status > 2 && item.fee_status !== 4) {
              item.disabled = true
            }
          })
          this.operateData = v.operation_list
          this.chargeFeeData = v.charge_fee_list
          this.checkInFeeData = v.fee_list
          this.chargeFeeData.forEach(v => {
            this.totalChargeFee += parseFloat(v.amount)
          })
          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
    },
    getFormBasicData() {
      getCmsMetaOption('inspect_type').then(v => {
        this.inspectType = v
      })
      getWmsDataOption('operator').then(v => {
        this.operatorOps = v
      })
    },
    getOperateData(data) {
      this.operateData = data
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.amount)
      })
      this.totalChargeFee = money
      return newData
    },
    changeInDate(date) {
      if (this.form.in_date && this.form.out_date) {
        if (this.form.in_date.isBefore(this.form.out_date)) {
          this.form.wms_days = this.form.out_date.diff(this.form.in_date, 'day')
        } else {
          this.form.wms_days = 0
        }
      } else {
        this.form.wms_days = undefined
      }
    },
    changeOutDate(date) {
      if (this.form.in_date && this.form.out_date) {
        if (this.form.in_date.isBefore(this.form.out_date)) {
          this.form.wms_days = this.form.out_date.diff(this.form.in_date, 'day')
        } else {
          this.form.wms_days = 0
        }
      } else {
        this.form.wms_days = undefined
      }
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            id: this.id,
            check_in_record: {
              ...this.form,
              purchase_date: this.form.purchase_date ? moment(this.form.purchase_date).valueOf() : null,
              release_date: this.form.release_date ? moment(this.form.release_date).valueOf() : null,
              in_date: this.form.in_date ? moment(this.form.in_date).valueOf() : null,
              out_date: this.form.out_date ? moment(this.form.out_date).valueOf() : null
            },
            operation_list:
              this.operateData &&
              this.operateData.map(g => {
                g.record_id = this.id
                return g
              }),
            charge_fee_list:
              this.chargeFeeData &&
              this.chargeFeeData.map(g => {
                if (g.fee_status === '') {
                  g.fee_status = null
                }
                g.record_id = this.id
                return g
              })
          }
          updateWmsCmsInfo(data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '编辑成功'
            })
            this.$router.push({
              name: 'WmsCmsBiz'
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
